export function getPathFromUrl(url: string): string {
  return url.split(/[?#]/)[0]
}

export function getFragmentFromUrl(url: string): string | undefined {
  return url.split('#')[1]
}

export function unlocalize(relativePath: string, locale: string): string {
  if (isAbsoluteUrl(relativePath)) {
    throw RangeError(`Expecting ${relativePath} to be a relative path`)
  }

  const path = new URL(relativePath, 'https://domain').pathname

  if (path.startsWith(`/${locale}`)) {
    return path.slice(`/${locale}`.length)
  }

  return path
}

export function isAbsoluteUrl(route: string): boolean {
  return new RegExp('^(?:[a-z]+:)?//', 'i').test(route)
}

export function parseUrl(urlString: string): URL {
  if (urlString.startsWith('//')) {
    urlString = `https:${urlString}`
  }
  return new URL(urlString)
}
